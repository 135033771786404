import { useTranslation } from 'react-i18next';
import {PlayItemWrapper} from "./style";
import Image from 'react-bootstrap/Image';
function PlayItem({data,onPlayVideo}) {
  return (
    <PlayItemWrapper>
        <div onClick={()=>onPlayVideo(data.url)}>
            <Image width='100%'  height='100%' src={data.picUrl} />
        </div>
        <div className="play-block">
            <h6 className='font-weight-bold'>{data.title}</h6>
            <p className='lead-light'>{data.des}</p>
            <p className='font-weight-bold'>{data.author}</p>
        </div>
    </PlayItemWrapper>
  );
}

export default PlayItem;