import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import {AppFigureWrapper} from "./style";
function AppBlock({hasMore = false}){
  const { t } = useTranslation();
  if(hasMore){
    return (
      <a className="btn btn-success btn-lg" href="#" role="button">{t('more')}</a>
    )
  }else{
    return null
  }
}
function AppFigure({title,subject,imgSrc,hasMore}) {
  return (
    <AppFigureWrapper className='bg-custom-light'>
      <Container >
        <div className="d-flex justify-content-center mh-30rem py-11">
          <div className="align-self-center">
            <h1 className="hero-title mb-2">{title}</h1>
            <div className="lead ">{subject}</div>
          </div>
        </div>
      </Container>
    </AppFigureWrapper>
  );
}

export default AppFigure;