import Container from 'react-bootstrap/Container';
import AppFigure from "@/components/app-figure";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import {MarketWrapper} from "./style";
import pptFile from "@/assets/file/2019中国美妆市场概览与发展趋势.pptx"
// import researchImg from "@/assets/img/research.jpg"
function MarketComestic() {
  const { t } = useTranslation();

  const openPptx = () => {
    window.open(pptFile, "_blank");
  };
  return (
    <MarketWrapper>
      <AppFigure title={t('market-comestics')} subject={t('market-comestics-subject')} />
      <Container>
        <section className="section pt-5 pb-5" data-aos="fade-up">
          <Row className="align-items-start">
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none' }}>
                <Card.Img variant="top" src="/img/comestic/comestic1.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-comestics-h1")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-comestics-p1')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
            <Card bg="light" style={{ border:'none' }}>
                <Card.Img variant="top" src="/img/comestic/comestic2.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-comestics-h2")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-comestics-p2')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none' }}>
                <Card.Img variant="top" src="/img/comestic/comestic3.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-comestics-h3")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-comestics-p3')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        <section className="section pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <h2 className="hero-title mb-2">{t("market-comestics-h4")}</h2>
            <p className="lead">{t("market-comestics-p4")}</p>
            <div className="footer">
              <div className="btn-view" onClick={openPptx} >
                  <img alt="查看" src="/img/view.png" />
                  <span>{t("market-btn-view")}</span>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </MarketWrapper>
  );
}

export default MarketComestic;
