import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Advise from "./advise"
import ControllCarousel from "./controllCarousel";
import {HomeWrapper} from "./style";
// import visionImg from "@/assets/img/vision.jpg"
// import valueImg from "@/assets/img/value.jpg"
function Home() {
  const { t } = useTranslation();
  return (
    <HomeWrapper>
      <Advise />
      <Container>
        <section className="section pt-5 pb-5" data-aos="fade-up">
          <Row className="align-items-start">
            <Col md={{ span: 6 }}>
              <Card bg="light" style={{ height: '16rem',border:'none' }}>
                <Card.Body>
                  <h2 className="hero-title mb-2">{t("mission-h")}</h2>
                  <p className="lead">{t('mission')}</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 6 }}>
              <Card bg="light" style={{ height: '16rem',border:'none'}}>
                <Card.Body>
                  <h2 className="hero-title mb-2">{t("value-h")}</h2>
                  <p className="lead">{t('value')}</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        <section className="section pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <h2 className="hero-title mb-2">{t("product-h")}</h2>
            <Row>
              <Col md={{ span: 6 }}>
                <ControllCarousel />
              </Col>
            </Row>
            
          </div>
        </section>
      </Container>
    </HomeWrapper>
  );
}

export default Home;