import React, { memo } from 'react';
import AppHeader from '@/components/app-header'
import { HashRouter } from "react-router-dom";
import routes from "@/router"
import { renderRoutes } from "react-router-config";
export default memo(function Layout(props){
    return (
        <HashRouter>
            <AppHeader />
            <div>
                {renderRoutes(routes)}
            </div>
        </HashRouter> 
    )
})