import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import logoSrc from '@/assets/img/logo1.png'
import { useTranslation } from 'react-i18next';
import {
  AppHeaderWrapper,
} from "./style";
export default function AppHeader() {
    const { t, i18n } = useTranslation();
    function handleChangeLang(lang) {
        i18n.changeLanguage(lang); 
    }
    return (
      <AppHeaderWrapper className="bg-body-tertiary">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="#home">
              <img alt="" className="logo" src="/img/logo1.png" />
              <span>
                <strong>{t('companyName')}</strong>
              </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto nav-auto"></Nav>
              <Nav>
                <Nav.Link href="#home">{t('menu-about')}</Nav.Link>
                <NavDropdown title={t('menu-product')} id="basic-nav-dropdown">
                  <NavDropdown.Item href="#medical">{t('menu-medical')}</NavDropdown.Item>
                  <NavDropdown.Item href="#medicine">{t('menu-medicine')}</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={t('menu-market')} id="basic-nav-dropdown">
                  <NavDropdown.Item href="#marketMedical">{t('market-medical')}</NavDropdown.Item>
                  <NavDropdown.Item href="#marketMedicine">{t('market-medicine')}</NavDropdown.Item>
                  <NavDropdown.Item href="#marketCosmetics">{t('market-comestics')}</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#research">{t('menu-research')}</Nav.Link>
                <Nav.Link href="#news">{t('menu-news')}</Nav.Link>
                <Nav.Link href="#contact">{t('menu-contact')}</Nav.Link>
                <NavDropdown
                    title={t('menu-language')}
                    variant="text"
                >
                    <NavDropdown.Item onClick={e=>handleChangeLang('zh')}>{t('menu-zh')}</NavDropdown.Item>
                    <NavDropdown.Item onClick={e=>handleChangeLang('en')}>{t('menu-en')}</NavDropdown.Item>
                    <NavDropdown.Item onClick={e=>handleChangeLang('trZh')}>{t('menu-trZh')}</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </AppHeaderWrapper>
      );
}