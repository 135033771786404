export const playList=[
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/02/2023_Feb_SClass%EA%B9%80%ED%83%9C%EC%99%84%EA%B5%90%EC%88%98_01-%EC%8D%B8%EB%84%A4%EC%9D%BC.png",
        title: "Part1. Explantation & Post treatment",
        des: "#THEGraftCollagen #Biotex",
        author:"Prof.Taewan Kim",
        url: "https://player.vimeo.com/video/794715594?h=1f8c240692&badge=0&autopause=0&player_id=0&app_id=58479"
    },
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/02/%EB%B0%95%EC%A7%84%EC%98%81-%EA%B5%90%EC%88%98-%EC%8D%B8%EB%84%A4%EC%9D%BC.png",
        title: "Bone Graft Paricle size, Does it matter?",
        des: "#THEGraft #BoneGraft",
        author:"Prof.Jin-Young Park",
        url: "https://player.vimeo.com/video/786149989?h=240a44b2f0&badge=0&autopause=0&player_id=0&app_id=58479"
    },
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/02/file_get_img-4.png",
        title: "[#11] Ridge augmentation and implant placement",
        des: "#THEGraft #CollagenMembrane",
        author:"Dr.Hong Soon Xae",
        url: "https://player.vimeo.com/video/681124752?h=02d70ee89c&badge=0&autopause=0&player_id=0&app_id=58479"
    },
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/02/file_get_img-5.jpg",
        title: "[#22, 23] Immediate Implant in maxillary anterior region",
        des: "#THEGraft #CollagenMembrane",
        author:"Dr.Hong Soon Xae",
        url: "https://player.vimeo.com/video/681125364?h=037ae28369&badge=0&autopause=0&player_id=0&app_id=58479"
    },
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/01/file_get_img-1.jpg",
        title: "WHY THE Graft™ - Porosity",
        des: "#WHYTG #Porosity",
        author:"WHY TG Series #1",
        url: "https://www.youtube.com/watch?v=Z5wGNEYfTGI"
    },
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/01/file_get_img-2-scaled.jpg",
        title: "Why THE Graft™ - Wettability",
        des: "#PurgoRegen #GlobalSymposium",
        author:"Europerio 2022 - Copenhagen",
        url: "https://www.youtube.com/watch?v=eX5Tan2osLQ"
    },
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/01/file_get_img.jpg",
        title: "Europerio 2022 with Purgo",
        des: "#Europerio2022 #Purgo",
        author:"Europerio 2022 - Copenhagen",
        url: "https://www.youtube.com/watch?v=g5teXF2FZz8"
    },
    {
        picUrl: "https://www.purgo-europe.com/wp-content/uploads/2023/01/EAO%EC%98%81%EC%83%81%ED%85%9C%ED%94%8C%EB%A6%BF%ED%8E%B8%EC%A7%91-2%EC%B0%A8-%EC%9E%91%EC%97%85.mp4_20221221_141252.233.jpg",
        title: "EAO 2022 with Purgo",
        des: "#EAO2022 #Purgo",
        author:"EAO 2022 - Geneva",
        url: "https://www.youtube.com/watch?v=0YJzWcHn-8M"
    }
]