import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import React from 'react';
import AppFigure from "@/components/app-figure";
// import graftBoxPng from "@/assets/img/graft-box-500px.png"
// import injectPng from "@/assets/img/inject.png"
// import THEGraftVISU3Png  from "@/assets/img/THEGraftVISU3.png"
// import THEGraftVISU2Png  from "@/assets/img/THEGraftVISU2.png"
// import THEPng  from "@/assets/img/THE.png"
// import GrafthydroPng  from "@/assets/img/THE-Grafthydro.png"
// import TGC01Png  from "@/assets/img/TGC-01.png"
// import ffdgdfgcfbPng  from "@/assets/img/ffdgdfgcfb-600x222.png"
// import HistologyPng  from "@/assets/img/TG-TGC-Histology_1-1536x592.png"
// import l9Png from "@/assets/img/l9-120x120.png"
// import l10Png from "@/assets/img/l10-120x120.png"
// import l8Png from "@/assets/img/l8-120x120.png"
// import TGCBlockPng from "@/assets/img/TGC-Block-and-Ring.png"
import {MedicalWrapper} from "./style";
import MedicalColthe from './medicalColthe';
function Medical() {
  const { t } = useTranslation();
  return (
    <MedicalWrapper>
      <AppFigure title={t('medical-title')} subject={t('medical-subject')} />
      <Container className="pt-4 pb-4">
        <section className="pt-5 pb-5" data-aos="fade-up">
          <h3 className="font-weight-bold">{t('graft-h1')}</h3>
          <div className="row  align-items-start">
            <div className="col-md-4">
              <div>
                <Image width="100%" src="/img/graft-box-500px.png" />
                <Image width="100%" src="/img/inject.png" />
              </div>
            </div>
            <div className="col-md-4 text-left">
              <p>{t('graft-p1_1')}</p>
            </div>
            <div className="col-md-4 text-left">
              <p >{t('graft-p1_2')}</p>
              <p >{t('graft-p1_3')}</p>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <div className="row  align-items-start">
            <div className="col-md-4">
              <h4 className="color-purple font-weight-bold">{t('graft-h2')}</h4>
              <h6 className="font-weight-bold">{t('graft-p2_1')}</h6>
              <p >{t('graft-p2_2')}</p>
              <p >{t('graft-p2_3')}</p>
              <Image width="100%" src="/img/THEGraftVISU3.png" />
              <Image width="100%" src="/img/THEGraftVISU2.png" />
            </div>
            <div className="col-md-4 text-left">
              <h4 className="color-purple font-weight-bold">{t('graft-h3')}</h4>
              <h6 className="font-weight-bold">{t('graft-p3_1')}</h6>
              <p >{t('graft-p3_2')}</p>
              <Image width="100%" src="/img/THE.png" />
            </div>
            <div className="col-md-4 text-left">
              <h4 className="color-purple font-weight-bold">{t('graft-h4')}</h4>
              <p >{t('graft-p4_1')}</p>
              <p >{t('graft-p4_2')}</p>
              <Image width="100%" src="/img/THE-Grafthydro.png" />
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <h4 className="font-weight-bold">{t('collagen-title')}</h4>
          <div className="row  align-items-start">
            <div className="col-md-4">
              <div>
                <Image width="100%" src="/img/TGC-01.png" />
              </div>
            </div>	
            <div className="col-md-4 text-left">
              <p>{t('collagen-p1_1')}</p>
              <p>{t('collagen-p1_2')}</p>
            </div>
            <div className="col-md-4 text-left">
              <p>{t('collagen-p2_1')}</p>
              <p>{t('collagen-p2_2')}</p>
              <div>
                <Image width="100%" src="/img/ffdgdfgcfb-600x222.png" />
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <div className="row  align-items-start">
            <div className="col-md-4">
              <h4 className="color-purple font-weight-bold">{t('collagen-p3_1')}</h4>
              <p>{t('collagen-p3_2')}</p>
              <p>{t('collagen-p3_3')}</p>
              <Image width="100%" src="/img/TG-TGC-Histology_1-1536x592.png" />
            </div>
            <div className="col-md-4 text-left">
            <h4 className="color-purple font-weight-bold">{t('collagen-p4_1')}</h4>
              <p>{t('collagen-p4_2')}</p>
              <p>{t('collagen-p4_3')}</p>
              <div>
                <Image width="120px" src="/img/l9-120x120.png" />
                <Image width="120px" src="/img/l10-120x120.png" />
                <Image width="120px" src="/img/l8-120x120.png" />
              </div>
            </div>
            <div className="col-md-4 text-left">
              <h4 className="color-purple font-weight-bold">{t('collagen-p5_1')}</h4>
              <p>{t('collagen-p5_2')}</p>
              <p>{t('collagen-p5_3')}</p>
              <Image width="100%" src="/img/TGC-Block-and-Ring.png" />
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <h4 className="font-weight-bold">{t('opentex-title')}</h4>
          <div className="row align-items-center">
            <div className="col-md-4">
              <div>
                <Image width="100%" src="/img/box-opentex-500B.png" />
              </div>
            </div>	
            <div className="col-md-4 text-left">
              <h6 className="font-weight-bold">{t('opentex-h1')}</h6>
              <p>{t('opentex-p1')}</p>
            </div>
            <div className="col-md-4 text-left">
              <div>
                <Image width="100%" src="/img/OpenTexVISU2.png" />
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <h4 className="color-purple font-weight-bold">{t('opentex-h2')}</h4>
          <div className="row align-items-center">
            <Image width="100%" src="/img/OpenTexVISU9.png" />
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-start">
            <div className="col-md-4">
              <h4 className="color-purple font-weight-bold">{t('opentex-h3')}</h4>
              <p>{t('opentex-p3_1')}</p>
              <p>{t('opentex-p3_2')}</p>
              <Image width="100%" src="/img/OpenTexVISU7.png" />
            </div>	
            <div className="col-md-4 text-left">
            <h4 className="color-purple font-weight-bold">{t('opentex-h4')}</h4>
              <h6>{t('opentex-h4_1')}</h6>
              <p>{t('opentex-p4_1')}</p>
              <h6>{t('opentex-h4_2')}</h6>
              <p>{t('opentex-p4_2')}</p>
              <h6>{t('opentex-h4_3')}</h6>
              <p>{t('opentex-p4_3')}</p>
            </div>
            <div className="col-md-4 text-left">
              <h4 className="color-purple font-weight-bold">{t('opentex-h5')}</h4>
              <h6>{t('opentex-h5_1')}</h6>
              <p>{t('opentex-p5_1')}</p>
              <p>{t('opentex-p5_2')}</p>
              <p>{t('opentex-p5_3')}</p>
              <Image width="100%" src="/img/OpenTexVISU8.png" />
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <Image width="100%" src="/img/sem-opnew.png" />
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <h4 className="font-weight-bold">{t('biotex-title')}</h4>
          <div className="row align-items-center">
            <div className="col-md-4">
              <div>
                <Image width="100%" src="/img/box-biotex-500b.png" />
              </div>
            </div>	
            <div className="col-md-4 text-left">
              <p>{t('biotex-p')}</p>
            </div>
            <div className="col-md-4 text-left">
              <div>
                <Image width="100%" src="/img/biotexVISU600pxB-1.png" />
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <h4 className="color-purple font-weight-bold">{t('biotex-h')}</h4>
          <div className="row align-items-start">
            <div className="col-md-4">
              <h6 className="color-purple">{t('biotex-h1')}</h6>
              <p>{t('biotex-p1_1')}</p>
              <p>{t('biotex-p1_2')}</p>
              <Image width="100%" src="/img/biotexVISU3-e1534514359628.png" />
            </div>
            <div className="col-md-4 text-left">
              <h6 className="color-purple">{t('biotex-h2')}</h6>
              <p>{t('biotex-p2_1')}</p>
              <p>{t('biotex-p2_2')}</p>
              <p>{t('biotex-p2_3')}</p>
              <Image width="100%" src="/img/biotexVISU9.png" />
            </div>
            <div className="col-md-4 text-left">
              <h6 className="color-purple">{t('biotex-h3')}</h6>
              <p>{t('biotex-p3_1')}</p>
              <p>{t('biotex-p3_2')}</p>
              <Image width="100%" src="/img/biotexVISU5.png" />
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <h4 className="color-purple font-weight-bold">{t('biocover-title')}</h4>
          <div className="row align-items-start">
            <div className="col-md-4">
              <Image width="100%" src="/img/box-biocover-500b.png" />
            </div>
            <div className="col-md-4 text-left">
              <h6 className="color-purple">{t('biocover-h')}</h6>
              <p>{t('biocover-p')}</p>
            </div>
            <div className="col-md-4 text-left">
              <Image width="100%" src="/img/BiocoverVISU2.png" />
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-start">
            <div className="col-md-4">
              <Image width="100%" src="/img/BiocoverVISU7.png" />
            </div>
            <div className="col-md-4 text-left">
              <h6 className="color-purple">{t('biocover-h1')}</h6>
              <p>{t('biocover-p1_1')}</p>
              <p>{t('biocover-p1_2')}</p>
            </div>
            <div className="col-md-4 text-left">
              <h6 className="color-purple">{t('biocover-h2')}</h6>
              <h4 className="color-purple text-center">{t('biocover-p2')}</h4>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5" data-aos="fade-up">
          <h3 className="font-weight-bold">{t('xRay-title')}</h3>
          <MedicalColthe />
        </section>
        
      </Container>
    </MedicalWrapper>
  );
}

export default Medical;