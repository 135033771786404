import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
 
// 使用 require 动态加载语言文件
const en = require('./locales/en.json');
const zh = require('./locales/zh.json');
const trZh = require('./locales/tr-zh.json');
 
const resources = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  },
  trZh:{
    translation: trZh
  }
};
 
i18n
  .use(initReactI18next) // 使用 react-i18next 初始化
  .init({
    resources,
    lng: 'zh', // 默认语言
    keySeparator: false, //  we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
 
export default i18n;