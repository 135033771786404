import Container from 'react-bootstrap/Container';
import { Button, Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import AppFigure from "@/components/app-figure"
import PlayItem from "@/components/play-item"
import {NewsWrapper} from "./style";
import { playList } from '../../services/local-data';
function News() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [source, setSource] = useState('');
  // 模态框是否显示
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();

  // 播放视频
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  // 播放
  const handlePlayVideo = (src) => {
    showModal();
    setSource(src);
  };



  // 暂停视频
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // 显示模态框
  const showModal = () => {
    setIsModalVisible(true);
  };

  // 隐藏模态框
  const hideModal = () => {
    pauseVideo();
    setIsModalVisible(false);
  };
  return (
    <NewsWrapper>
      {/* <AppFigure title={t('news-title')} subject={t('news-subject')} /> */}
      <Container className="pt-4 pb-4">
        <h3 className="display-6 font-weight-bold text-center text-warning">{t('news-h1')}</h3>
        <p className="lead">{t('news-p1')}</p>
        <Row className="pt-4">{
          playList.map(item=>(
            <Col md={{ span: 3 }} v-for="item in pla">
              <PlayItem 
                data={item}
                onPlayVideo={handlePlayVideo}
              />
            </Col>
          ))
        } 
        </Row>
      </Container>
      <div>
        <Modal size="lg" show={isModalVisible} onHide={hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Video Player</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video ref={videoRef} width="100%" height="100%" controls> 
              <source src={source} type="video/mp4" />
            </video>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </NewsWrapper>
  );
}

export default News;