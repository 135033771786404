import Container from 'react-bootstrap/Container';
import AppFigure from "@/components/app-figure";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import {MarketWrapper} from "./style";
import medicineFile from "@/assets/file/每周洗脑返老还童.pdf"
// import researchImg from "@/assets/img/research.jpg"
function MarketMedicine() {
  const { t } = useTranslation();

  const openPdf = () => {
    window.open(medicineFile, "_blank");
  };
  return (
    <MarketWrapper>
      <AppFigure title={t('market-medicine')} subject={t('market-medicine-subject')} />
      <Container>
        <section className="section pt-5 pb-5" data-aos="fade-up">
          <Row className="align-items-start">
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none' }}>
                <Card.Img variant="top" src="/img/market/img1.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medicine-h1")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medicine-p1')}</p>
                  </Card.Text>
                  {/* <h2 className="hero-title mb-2">{t("market-medicine-h1")}</h2>
                  <p className="lead">{t('market-medicine-p1')}</p> */}
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img2.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medicine-h2")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medicine-p2')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img3.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medicine-h3")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medicine-p3')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="align-items-start">
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img4.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medicine-h4")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medicine-p4')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img5.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medicine-h5")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medicine-p5')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img6.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medicine-h6")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medicine-p6')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
        <section className="section pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <h2 className="hero-title mb-2">{t("market-medicine-h7")}</h2>
            <p className="lead">{t("market-medicine-p7")}</p>
            <div className="footer">
              <div className="btn-view" onClick={openPdf} >
                  <img alt="查看" src="/img/view.png" />
                  <span>{t("market-btn-view")}</span>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </MarketWrapper>
  );
}

export default MarketMedicine;
