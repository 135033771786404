import styled from "styled-components";

export const AdviseWrapper = styled.div`
  background-image: url("/img/line-bg.png");
  min-height:25rem;
  .home-logo {
    width:100px;
    max-height:150px;
  }

  .nav-auto{
    flex:1;
  }


  .divider {
    height: 5px;
    background-color: #C20C0C;
  }
`

