import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import AppFigure from "@/components/app-figure"
import {ResearchWrapper} from "./style";
// import researchImg from "@/assets/img/research.jpg"
function Research() {
  const { t } = useTranslation();
  return (
    <ResearchWrapper>
      <AppFigure title={t('research-title')} subject={t('research-subject')} />
      <Container>
        {/* <section className="section pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div>
                <h3  className="display-6 font-weight-bold text-center text-warning">{t('research-title')}</h3>
                <p className="lead">{t('research-introduction')}</p>
                <p className="lead-light">{t('research-introduction-des')}</p>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <h3 className="font-weight-bold  text-warning">{t('research-introduction-h1')}</h3>
              <p className="lead">{t('research-introduction-p1')}</p>
              <h3 className="font-weight-bold  text-warning">{t('research-introduction-h2')}</h3>
              <p className="lead">{t('research-introduction-p2')}</p>
              <h3 className="font-weight-bold  text-warning">{t('research-introduction-h3')}</h3>
              <p className="lead">{t('research-introduction-p3')}</p>
            </div>
          </div>
        </section> */}

        <section className="section pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div>
                <Image width="100%" src="/img/research.png" />
              </div>
            </div>
            <div className="col-md-6 ">
              <h3  className="display-6 font-weight-bold text-center text-primary">{t('research-model-title')}</h3>
              <div className='text-right'>
                <h3 className="display-6 font-weight-bold">01</h3>
                <h5 className="font-weight-bold ">{t('research-model-h1')}</h5>
                <p className="lead">{t('research-model-p1')}</p>
              </div>
              <div  className="text-right">
              <h3 className="display-6 font-weight-bold text-right">02</h3>
                <h5 className="font-weight-bold">{t('research-model-h2')}</h5>
                <p className="lead">{t('research-model-p2')}</p>
              </div>
              <div  className="text-right">
              <h3 className="display-6 font-weight-bold text-right">03</h3>
                <h5 className="font-weight-bold">{t('research-model-h3')}</h5>
                <p className="lead">{t('research-model-p3')}</p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-md-6 ">
              <h3  className="display-6 font-weight-bold text-center text-success">{t('research-development-t')}</h3>
              <p className="lead">{t('research-development-p1')}</p>
              <p className="lead">{t('research-development-p2')}</p>
              <p className="lead">{t('research-development-p3')}</p>
              <p className="lead">{t('research-development-p4')}</p>
            </div>
            <div className="col-md-6">
              <div>
                <Image width="100%" src="/img/research.png" />
              </div>
            </div>
          </div>
        </section> */}
      </Container>

    </ResearchWrapper>
  );
}

export default Research;