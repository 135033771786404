import styled from "styled-components";

export const HomeWrapper = styled.div`
 .section{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
 }

 .col-md-6{
   margin:1rem 0;
 }
`

