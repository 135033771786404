import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import {AdviseWrapper} from "./style";
// import graftImg from "@/assets/img/graft-box-500px.png"
function Advise() {
  const { t } = useTranslation();
  return (
    <AdviseWrapper className='bg-custom-light text-center'>
      <div className='full-img'></div>
      <Container >
        <div className="d-flex justify-content-center mh-30rem py-11">
          <div className="align-self-center">
            <h1 className="hero-title mb-2">{t('subject')}</h1>
            <div className="lead ">{t('about')}</div>
            <a className="btn btn-success btn-lg mt-4" href="#medical" role="button">{t('more')}</a>
          </div>
        </div>
      </Container>
    </AdviseWrapper>
  );
}

export default Advise;