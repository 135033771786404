import { Redirect } from "react-router-dom";
import Home from "@/pages/home"
import Medical from "@/pages/medical"
import Medicine from "@/pages/medicine"
import Cosmetics from "@/pages/cosmetics"
import Research from "@/pages/research"
import Contact from "@/pages/contact"
import News from "@/pages/news"
import Market from "@/pages/market"
import MarketMedicine from "@/pages/market/marketMedicine"
import MarketMedical from "@/pages/market/marketMedical"
import MarketComestic from "@/pages/market/marketComestic"

const routes = [
    {
      path: "/",
      exact: true,
      render: () => (
        <Redirect to="/home"/>
      )
    },{
        path: "/home",
        component: Home,
    },
    {
      path: "/medical",
      component: Medical,
    },
    {
      path: "/medicine",
      component: Medicine,
    },
    {
      path: "/cosmetics",
      component: Cosmetics,
    },
    {
      path: "/marketMedical",
      component: MarketMedical,
    },
    {
      path: "/marketMedicine",
      component: MarketMedicine,
    },
    {
      path: "/marketCosmetics",
      component: MarketComestic,
    },
    {
      path: "/research",
      component: Research,
    },
    {
      path: "/news",
      component: News,
    },
    {
      path: "/contact",
      component: Contact,
    }
]
export default routes;