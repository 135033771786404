import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import React from 'react';
import AppFigure from "@/components/app-figure";
import {MedicineWrapper} from "./style";
function Medicine() {
  const { t } = useTranslation();
  return (
    <MedicineWrapper>
      {/* <AppFigure title={t('medicine-title')} subject={t('medicine-subject')} /> */}
      <Container className="pt-4 pb-4">
        <p className="display-6 text-center">{t('medicine-p')}</p>
      </Container>
    </MedicineWrapper>
  );
}

export default Medicine;