import styled from "styled-components";

export const ContactWrapper = styled.div`
 .section{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
 }
`

