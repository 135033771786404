import { Row,Image } from "react-bootstrap";
import {MedicalColtheWrapper} from "./style";
function MedicalColthe(){
    return (
        <MedicalColtheWrapper>
            <Row>
                <Image src="/img/medicalColthe/img_01.png" />
            </Row>
            <Row>
                <Image src="/img/medicalColthe/img_03.png" />
            </Row>
            <Row>
                <Image src="/img/medicalColthe/img_05.png" />
            </Row>
            <Row>
                <Image src="/img/medicalColthe/img_07.png" />
            </Row>
            <Row>
                <Image src="/img/medicalColthe/img_09.png" />
            </Row>
            <Row>
                <Image src="/img/medicalColthe/img_11.png" />
            </Row>
            <Row>
                <Image src="/img/medicalColthe/img_13.png" />
            </Row>
        </MedicalColtheWrapper>
    )
}

export default MedicalColthe