import styled from "styled-components";

export const MedicalWrapper = styled.div`
 .section{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
 }
`

export const MedicalColtheWrapper = styled.div`

   .colthe_img{
      position:absolute; 
      width:49.5833em; 
      height:67.3333em;
   }
   .stl_ sup {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
   }
   .stl_ sub {
      vertical-align: baseline;
      position: relative;
      top: 0.4em;
   }
   .stl_ a:link {text-decoration:none;}
   .stl_ a:visited {text-decoration:none;}
   @media screen and (min-device-pixel-ratio:0), (-webkit-min-device-pixel-ratio:0), (min--moz-device-pixel-ratio: 0) {.stl_view{ font-size:10em; transform:scale(0.1); -moz-transform:scale(0.1); -webkit-transform:scale(0.1); -moz-transform-origin:top left; -webkit-transform-origin:top left; } }
   .stl_layer { }.stl_ie { font-size: 1pt; }
   .stl_ie body { font-size: 12em; }
   @media print{.stl_view {font-size:1em; transform:scale(1);}}
   .stl_grlink { position:relative;width:100%;height:100%;z-index:1000000; }
   .stl_01 {
      position: absolute;
      white-space: nowrap;
   }
   .stl_02 {
      font-size: 1em;
      line-height: 0.0em;
      width: 49.58333em;
      height: 67.33334em;
      border-style: none;
      display: block;
      margin: 0em;
   }

   @supports(-ms-ime-align:auto) { .stl_02 {overflow: hidden;}}
   .stl_03 {
      position: relative;
   }
   .stl_04 {
      position: absolute;
      left: 0em;
      top: 0em;
   }
`

