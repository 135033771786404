import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import React from 'react';
import AppFigure from "@/components/app-figure";
import {CosmeticsWrapper} from "./style";
// import pdfFile from "@/assets/file/2020美妆报告.pdf"
// import pptFile from "@/assets/file/2019中国美妆市场概览与发展趋势.pptx"
function Cosmetics() {
  const { t } = useTranslation();
  return (
    <CosmeticsWrapper>
      <AppFigure title={t('cosmetics-title')} subject={t('cosmetics-subject')} />
      <Container className="pt-4 pb-4">
        <p className="text-align-center">建设中</p>
      </Container>
    </CosmeticsWrapper>
  );
}

export default Cosmetics;