import styled from "styled-components";

export const AppHeaderWrapper = styled.div`
  font-size: 16px;

  .navbar-brand{
    margin-left:-12px;
  }
  .logo {
    max-height:75px;
  }

  .nav-auto{
    flex:1
  }

  .divider {
    height: 5px;
    background-color: #C20C0C;
  }
`

