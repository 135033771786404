import Container from 'react-bootstrap/Container';
import AppFigure from "@/components/app-figure";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import {MarketWrapper} from "./style";
import medicineFile from "@/assets/file/每周洗脑返老还童.pdf"
// import researchImg from "@/assets/img/research.jpg"
function MarketMedical() {
  const { t } = useTranslation();

  const openPdf = () => {
    window.open(medicineFile, "_blank");
  };
  return (
    <MarketWrapper>
      <AppFigure title={t('market-medical')} subject={t('market-medical-subject')} />
      <Container>
        <section className="section pt-5 pb-5" data-aos="fade-up">
          <Row className="align-items-start">
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none' }}>
                <Card.Img variant="top" src="/img/market/img1.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medical-h1")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medical-p1')}</p>
                  </Card.Text>
                  {/* <h2 className="hero-title mb-2">{t("market-medical-h1")}</h2>
                  <p className="lead">{t('market-medical-p1')}</p> */}
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img2.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medical-h2")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medical-p2')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img3.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medical-h3")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medical-p3')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="align-items-start">
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img4.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medical-h4")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medical-p4')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img5.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medical-h5")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medical-p5')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={{ span: 4 }}>
              <Card bg="light" style={{ border:'none'}}>
                <Card.Img variant="top" src="/img/market/img6.png" />
                <Card.Body>
                  <Card.Title>
                    <h2 className="hero-title mb-2">{t("market-medical-h6")}</h2>
                  </Card.Title>
                  <Card.Text>
                    <p className="lead">{t('market-medical-p6')}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        <section className="section pt-5 pb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <h2 className="hero-title mb-2">{t("market-medical-h7")}</h2>
            <p className="lead">
              {t("market-medical-p7")}
            </p>
            {/* <p>
                <Button variant="link" onClick={openPdf}>{t("market-medical-p4")}</Button>
            </p> */}
          </div>
        </section>
      </Container>
    </MarketWrapper>
  );
}

export default MarketMedical;
