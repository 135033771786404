import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import React from 'react';
import AppFigure from "@/components/app-figure";
import {ContactWrapper} from "./style";
import Image from 'react-bootstrap/Image';
function Contact() {
  const { t } = useTranslation();
  return (
    <ContactWrapper>
      <Container className="pt-4 pb-4">
        <section className="pt-5 pb-5" data-aos="fade-up">
          <h4 className="font-weight-bold">{t('contact-address-h')}</h4>
          <div className="mt-4  row align-items-start">
            <div className="col-md-6">
              <Image width="50px" height="50px" src="/img/location.png" />
              <h6 className="font-weight-bold">{t('contact-address-p1_1')}</h6>
              <p>{t('contact-address-p1_2')}</p>
              <p>{t('contact-address-p1_3')}</p>
              <p>{t('contact-address-p1_4')}</p>
              <p>{t('contact-address-p1_5')}</p>
              <p>{t('contact-address-p1_6')}</p>
            </div>
            <div className="col-md-6">
              <Image width="50px" height="50px" src="/img/location.png" />
              <h6 className="font-weight-bold">{t('contact-address-p2_1')}</h6>
              <p>{t('contact-address-p2_2')}</p>
              <p>{t('contact-address-p2_3')}</p>
              <p>{t('contact-address-p2_4')}</p>
              <p>{t('contact-address-p2_5')}</p>
              <p>{t('contact-address-p2_6')}</p>
            </div>
          </div>
        </section>

        <section class="pt-5 pb-5" data-aos="fade-up">
          <h4 className="font-weight-bold">{t('contact-email-h')}</h4>
          <div className="mt-4 row align-items-start">
            <p>{t('contact-email-p1')}</p>
            <p>{t('contact-email-p2')}</p>
          </div>
        </section>

        <section class="pt-5 pb-5" data-aos="fade-up">
          <h4 className="font-weight-bold">{t('contact-other-h')}</h4>
          <div className="mt-4 row align-items-start">
            <p>{t('contact-other-p1')}</p>
            <p>{t('contact-other-p2')}</p>
            <p>{t('contact-other-p3')}</p>
          </div>
        </section>
      </Container>
    </ContactWrapper>
  );
}

export default Contact;