import styled from "styled-components";

export const MarketWrapper = styled.div`
 .section{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
 }

 .btn-view{
   margin-top:8px;
   cursor:pointer;
   width: 240px;
   text-align: center;
   padding: 16px;
   background-color: rgb(246, 248, 249);
   img{
      width:24px;
      height:24px;
      margin-right:4px;
   }
 }
`

